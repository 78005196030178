<template>
  <div>
    <v-container>
      <v-row  align="center">
        <no-content route-name title="404 По вашему запросу ничего не найдено!"></no-content>
      </v-row>
      
      <!-- <book-card v-for="(book, index) in books_top" :key="book.id" :book="book" :index="index"></book-card> -->
      <book-top
        class="mt-4"
        v-if="books_top && books_top.top_featureds"
        :books="books_top.top_featureds"
        title="Рекомендуем"
      ></book-top>
      
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BookTop from "./book/BookTop.vue";

export default {
  components: {
    BookTop,
  },
  data: () => ({}),
  metaInfo() {
    return {
      title: `404 Ничего не найдено! Читать книги онлайн бесплатно - Bookstab`,
      meta: [
        {
          name: "description",
          content:
            "Самая крупная литературная платформа и библиотека книг. Более 40.000 книг, которые можно бесплатно читать онлайн и скачать. Удобно читать с телефона, есть мобильная версия.",
        },
        {
          property: "og:title",
          content: "404 Ничего не найдено! Читать книги онлайн бесплатно - Bookstab",
        },
        { property: "og:site_name", content: "Bookstab" },
        {
          property: "og:description",
          content:
            "Самая крупная литературная платформа и библиотека книг. Более 40.000 книг, которые можно бесплатно читать онлайн и скачать. Удобно читать с телефона, есть мобильная версия.",
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: window.location.href },
      ],
    };
  },
  methods: {
    getBooks() {
      this.$store.dispatch("getBooksTop");
    },
  
  },
  computed: {
    ...mapGetters(["loadPage", "books_top", "siteSettings"]),
  },
  created() {
    this.getBooks();
  },
};
</script>
